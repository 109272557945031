import React, { useState } from "react";
import config from "../../config_frontend"; // Import the configuration
import "../../CSS_Files/AdminPanel/Invitationdialogbox.css";

const InvitationSuccess = ({ email, onClose }) => {
  const [copySuccess, setCopySuccess] = useState("");
  const [buttonColor, setButtonColor] = useState("");

  const urlToCopy = `${config.client.host[1]}/verifyuser/${email}`; // Access the URL

  const handleCopy = () => {
    const textArea = document.createElement("textarea");
    textArea.value = urlToCopy;
    textArea.style.position = "fixed";
    textArea.style.opacity = 0;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      if (successful) {
        setCopySuccess("Copied!");
        setButtonColor("green");
      } else {
        setCopySuccess("Failed to copy!");
        setButtonColor("red");
      }
    } catch (err) {
      setCopySuccess("Error copying text!");
      setButtonColor("red");
      console.error("Error in copying text: ", err);
    }

    document.body.removeChild(textArea);

    setTimeout(() => {
      setCopySuccess("");
      setButtonColor("");
    }, 2000);
  };

  return (
    <div className="invitation-dialog-container">
      <div className="invitation-dialog-close-button" onClick={onClose}>
        <img
          src="../../assets/Adminapp/closebutton.png"
          alt="Close Button"
          style={{ width: "15px" }}
        />
      </div>
      <div className="invitation-dialog-successful-image">
        <img
          src="../../assets/Adminapp/invitationsuccesful.png"
          alt="Successful"
          style={{ width: "20%" }}
        />
      </div>

      <div className="invitation-dialog-first-heading">
        Invitations Sent Successfully!
      </div>
      <div className="invitation-dialog-second-heading">
        <p>
          We sent an invitation to join qRIZZ at{" "}
          <br />
          <span style={{ paddingLeft: "30px" }}>{email}</span>
        </p>
      </div>

      <div className="invitation-dialog-bottom-section">
        <p>If you prefer, you can share the invitation link:</p>
        <div className="invitation-dialog-link-box">
          <img
            src="../../assets/Adminapp/link.png"
            alt="Link"
            style={{ width: "20px", marginLeft: "5px" }}
          />
          <input type="text" value={urlToCopy} readOnly />
          <button
            onClick={handleCopy}
            style={{
              backgroundColor: buttonColor || "#007bff",
              color: "white",
              border: "none",
              padding: "5px 10px",
              cursor: "pointer",
              borderRadius: "3px",
            }}
          >
            {copySuccess || "Copy"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvitationSuccess;
