import React, { useState, useEffect } from "react";
// import {Link} from "react-router-dom";
import "../../CSS_Files/AdminPanel/Dashboard.css";
import { Backdrop, CircularProgress } from "@mui/material";
import User from "../AdminPanel/User.js";
import axios from "axios";

const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const Dashboard = () => {
  const [adminData, setAdminData] = useState(null);
  const [queryUsed, setQueryUsed] = useState(0);
  const [queryTotal, setQueryTotal] = useState(0);
  const [docsUsed, setDocsUsed] = useState(0);
  const [docsTotal, setDocsTotal] = useState(0);
  const [databaseUsed, setDatabaseUsed] = useState(0);
  const [databaseTotal, setDatabaseTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const isLimitExceeded =
    docsUsed >= docsTotal ||
    queryUsed >= queryTotal ||
    databaseUsed >= databaseTotal;

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`${host}/auth/getAdmin`, {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("Updating dashboard data");
          setAdminData(res.data);
          setQueryUsed(res.data.queryCount);
          setQueryTotal(res.data.queryLimit);
          setDatabaseUsed(res.data.dbCount);
          setDatabaseTotal(res.data.dbLimit);
          setDocsUsed(res.data.docCount);
          setDocsTotal(res.data.docLimit);
          console.log("Admin data -> ", res.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));

      axios
        .get(`${host}/admin/getUsers`, {
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        })
        .then(async (res) => {
          console.log(res.data);
        })
        .catch((err) => console.log(err));
    };

    fetchData();
    const intervalId = setInterval(fetchData, 1000);
    return () => clearInterval(intervalId);
  }, []);

  console.log(adminData);
  return (
    <div className="AdminPanel_Dashboard_container">
      <div className="AdminPanel_Dashboard_Topcontainer">
        <div className="AdminPanel_Dashboard_leftcontainer"></div>
        <div className="AdminPanel_Dashboard_middlecontainer">
          {isLimitExceeded && (
            <div className="AdminPanel_LimitExceeded">
              <div className="AdminPanel_LimitExceeded_firstheading">Upgrade Now</div>
              <div className="AdminPanel_LimitExceeded_secondheading">
                You have reached 100% of your quota. Please Contact  <span style={{color:"#1F5BC5"}}>support@qrizz.ai</span> to buy more credits.
              </div>
              {/* <Link to="/upgrade">
                <button className="adminemail-button">
                  <span className="adminemail-ancor">Upgrade Now</span>
                </button>
              </Link> */}
            </div>
          )}
          <div className="AdminPanel_topcontainer-dashboard">
            <div className="AdminPanel_Queries">
              <div className="AdminPanel_QueriesText">Queries</div>
              <div className="AdminPanel_QueriesValue">
                {queryUsed}/{queryTotal}
              </div>
            </div>
            <div className="AdminPanel_License">
              <div className="AdminPanel_LicenseText">Documents</div>
              <div className="AdminPanel_LicenseValue">
                {docsUsed}/{docsTotal}
              </div>
            </div>
            <div className="AdminPanel_License">
              <div className="AdminPanel_LicenseText">Databases</div>
              <div className="AdminPanel_LicenseValue">
                {databaseUsed}/{databaseTotal}
              </div>
            </div>
          </div>
        </div>
        <div className="AdminPanel_Dashboard_rightcontainer"></div>
      </div>

      <div className="AdminPanel_Dashboard_Bottomcontainer">
        <User style={{ width: "100%" }} />
      </div>

      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
};

export default Dashboard;
