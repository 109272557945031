import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../../CSS_Files/UserPanel/signup.css";
import { useGoogleLogin } from "@react-oauth/google";
import Alert_modal from "../../scenes/Modals/alert_modal.jsx";

const config = require("../../config_frontend.js");
const host = config.server.host[process.env.REACT_APP_HOST];

const Signup = (props) => {
  let navigate = useNavigate();
  const [cred, setCred] = useState({
    name: "",
    email: "",
    password: "",
    cpassword: "",
  });

  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const onChange = (e) => {
    e.preventDefault();
    setCred({ ...cred, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (cred.password !== cred.cpassword) {
      setPasswordMatchError(true);
      setModalMessage("Passwords didn't match");
      setShowModal(true);
      return;
    }

    const url = `${host}/auth/createuser`;
    const { name, email, password } = cred;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, password }),
      });
      const output = await response.json();

      if (output.success) {
        localStorage.setItem("token", output.authToken);
        alert("Your account is created successfully! Please check your mail to verify the details.");
        navigate("/login");
      } else {
        setModalMessage("Invalid credentials");
        setShowModal(true);
      }

      console.log(output);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const signup = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const resp = await fetch(`${host}/auth/googleSignup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ access_token: response.access_token }),
        });

        const json = await resp.json();

        if (json.success) {
          localStorage.setItem("token", json.authToken);
          navigate("/emptychat");
        } else {
          setModalMessage("Invalid credentials");
          setShowModal(true);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  });

  return (
    <div className="signup-page-sp">
      <div className="card login-box-sp">
        <div className="items-center-sp self-center-sp">
          <img className="" alt="logo-sp" src="../../assets/Logo-Light.png"></img>
        </div>
        <div className="card-body-sp">
          <form onSubmit={handleSubmit}>
            <div className="no-account-sp mb-2-sp">
              <div className="dont-account-sp">Already have an account?</div>
              <div className="sign-up-sp">
                <Link id="signup" to="/login">
                  Sign IN
                </Link>
              </div>
            </div>
            <div className="mb-2-sp inputtt-sp">
              <label htmlFor="name" className="text-black-sp">
                Name
              </label>
              <input
                type="text"
                className="form-control-sp"
                id="name"
                name="name"
                value={cred.name}
                placeholder="Enter your name"
                onChange={onChange}
                minLength={5}
                required
              />
            </div>
            <div className="mb-2-sp inputtt-sp">
              <label htmlFor="email" className="text-black-sp">
                Email address
              </label>
              <input
                type="email"
                className="form-control-sp"
                id="email"
                name="email"
                placeholder="Enter your email"
                aria-describedby="emailHelp"
                value={cred.email}
                onChange={onChange}
                minLength={5}
                required
              />
            </div>
            <div className="mb-2-sp inputtt-sp">
              <label htmlFor="password" className="text-black-sp">
                Password
              </label>
              <input
                type="password"
                className="form-control-sp"
                id="password"
                name="password"
                placeholder="Enter password"
                value={cred.password}
                onChange={onChange}
                minLength={5}
                required
              />
            </div>
            <div className="mb-3-sp inputtt-sp">
              <label htmlFor="cpassword" className="text-black-sp">
                Confirm password
              </label>
              <input
                type="password"
                className="form-control-sp"
                id="cpassword"
                name="cpassword"
                placeholder="Confirm password"
                value={cred.cpassword}
                onChange={onChange}
                minLength={5}
                required
              />
              {passwordMatchError && (
                <div className="text-red-500-sp text-sm-sp mb-1-sp">
                  Passwords do not match
                </div>
              )}
            </div>
            <button type="submit" className="bt1-sp  rounded-lg-sp">
              Sign Up &rarr;
            </button>
            <div className="border-with-text-signup-sp">
              <div className="border-line-sp"></div>
              <div className="text-between-sp">Or sign up with</div>
              <div className="border-line-sp"></div>
            </div>

            <div className="buttons-row-signup-sp">
              <button className="btn_alt-sp" onClick={() => signup()}>
                <div className="btns-sp">
                  <img
                    className="btnimg-sp google-sp"
                    src={"../../assets/Google.png"}
                    alt="Google"
                  />
                  <div className="btntext-sp text-black-sp">Google</div>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
      <Alert_modal
        show={showModal}
        message={modalMessage}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default Signup;
