// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.modal-content p {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.modal-close-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background: linear-gradient(78.49deg, #e63407 -4.61%, #ff5b32 88.61%);
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.modal-close-btn:hover {
  background: #ff4a4a;
}
`, "",{"version":3,"sources":["webpack://./src/scenes/Modals/alert_modal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,SAAS;EACT,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,qEAAqE;EACrE,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.6);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.modal-container {\n  background: white;\n  padding: 20px;\n  border-radius: 8px;\n  text-align: center;\n  max-width: 400px;\n  width: 100%;\n}\n\n.modal-content p {\n  margin: 0;\n  font-size: 1.2rem;\n  color: #333;\n}\n\n.modal-close-btn {\n  margin-top: 20px;\n  padding: 10px 20px;\n  background: linear-gradient(78.49deg, #e63407 -4.61%, #ff5b32 88.61%);\n  border: none;\n  border-radius: 5px;\n  color: white;\n  cursor: pointer;\n}\n\n.modal-close-btn:hover {\n  background: #ff4a4a;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
